<script setup lang="ts">
import type SwiperCore from 'swiper'
import { A11y, Controller, Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const props = defineProps<{
  slides: {
    image: string
    text: string
    to: string
    buttonText: string
  }[]
  current?: number
  sliderOptions?: any
}>()

defineEmits<{
  (event: 'click', index: number): void
  (event: 'click:stage', index: number): void
}>()

const current = ref(props.current ?? 1)
const activeIndex = ref(current.value - 1)

const mainSwiper = ref<SwiperCore>()
const thumbsSwiper = ref<SwiperCore>()

// ------------------------------- MAIN SWIPER ------------------------------- //
function onSwiper(swiper: SwiperCore) {
  mainSwiper.value = swiper
}

function onSlideChange(event: SwiperCore) {
  activeIndex.value = event.activeIndex
}
defineExpose({
  swiper: mainSwiper,
})
</script>

<template>
  <div>
    <div class="md:mx-auto">
      <Swiper
        :modules="[A11y, Controller, Navigation, Thumbs]"
        :thumbs="{ swiper: thumbsSwiper }"
        :slides-per-view="1"
        :space-between="10"
        :navigation="{ nextEl: '.n-gallery-next', prevEl: '.n-gallery-prev' }"
        class="md:auto relative md:h-auto xs:h-[40vh]"
        :style="{
          '--swiper-pagination-color': '#F97316',
        }"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
      >
        <SwiperSlide v-for="(slide, index) in slides" :key="`slide-${index}`" class="h-full">
          <div class="relative h-full overflow-hidden">
            <!-- Gradient Overlay -->
            <div class="absolute inset-0 from-black/20 to-transparent bg-gradient-to-t" />
            <NuxtImg
              :src="slide.image"
              :alt="slide.image"
              class="h-full w-full select-none object-cover object-center-top"
              preload
            />
            <div class="slide-content absolute bottom-5 left-1/2 z-10 w-4/5 p-5 text-center -translate-x-1/2">
              <h2 v-if="slide.text" class="pb-2 text-[1.5rem] text-white font-black md:text-[3rem]">
                {{ slide.text }}
              </h2>
              <NLink v-if="(slide.to && slide.buttonText)" :to="slide.to" class="button-styles md:text-large m-auto block w-fit rounded-md bg-white px-3 py-1 text-sm no-underline md:px-5 md:py-2">
                {{ slide.buttonText }}
              </NLink>
            </div>
          </div>
        </SwiperSlide>

        <NButton
          id="gallery-prev"
          icon="i-mingcute:left-line" n="sm md:2xl"
          class="n-gallery-prev absolute left-2 top-1/2 z-20 inline-flex rounded-full bg-white p-0.25em op100 shadow-md ring ring-black/5 children:m-0 -mt6"
        />
        <NButton
          id="gallery-next"
          icon="i-mingcute:right-line" n=" sm md:2xl"
          class="n-gallery-next absolute right-2 top-1/2 z-20 inline-flex rounded-full bg-white p-0.25em op100 shadow-md ring ring-black/5 children:m-0 -mt6"
        />
      </Swiper>
    </div>
  </div>
</template>
